import NotificationRow from '../../NotificationRow';
import NotificationRowSkeleton from '../../NotificationRow/Skeleton';
import { NotificationRowType } from '../../NotificationRow/props';
import { Fragment, useMemo } from 'react';
import { useNotificationsMe } from '@/api/notifications';
import { RootState } from '@/lib/store';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Notification } from '@/models/notification/notification';
import { useInfiniteScroll } from '@starsoft/common/hooks';
import { ErrorCard, NoDataComponent } from '@starsoft/common/components';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';

export default function NotificationContentModal() {
  const tab: NotificationGroupId = useSelector(
    (state: RootState) => state.notifications.tab,
  );
  const {
    notifications,
    isPending,
    isFetchingNextPage,
    hasNextPage,
    error,
    fetchNextPage,
    refetch,
  } = useNotificationsMe({
    groupId: tab,
    refetchOnMount: 'always',
  });
  const { ref: scrollContainerRef } = useInfiniteScroll({
    hasMore: hasNextPage ?? false,
    isLoading: isPending || isFetchingNextPage,
    loadMore: () => fetchNextPage(),
  });
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId = components.get(
    ComponentTypeId.Notifications,
  );

  const Notifications: JSX.Element[] = useMemo(
    () =>
      notifications?.map((notification, i, array) => (
        <Fragment key={'notification-' + notification.id}>
          <NotificationRow
            notification={notification as Notification}
            type={NotificationRowType.Row}
          />
          {i < array?.length - 1 &&
            version === SettingLayoutComponentId.NotificationsV2 && (
              <div className={styles.container__divider} />
            )}
        </Fragment>
      )),
    [notifications, version],
  );

  const Skeletons: JSX.Element[] = useMemo(
    () =>
      Array.from({ length: 8 }).map((_, index, array) => (
        <Fragment key={`notification-skeleton-${index}`}>
          <NotificationRowSkeleton />
          {index < array?.length - 1 &&
            version === SettingLayoutComponentId.NotificationsV2 && (
              <div className={styles.container__divider} />
            )}
        </Fragment>
      )),
    [version],
  );

  function handleError() {
    refetch?.();
  }

  if (error) {
    return <ErrorCard error={error} refetch={handleError} />;
  }

  if (!isPending && notifications?.length === 0) {
    return (
      <div className={styles.container}>
        <NoDataComponent />
      </div>
    );
  }

  return (
    <div
      className={`${styles.container} ${version === SettingLayoutComponentId.NotificationsV2 ? styles['container--gaplesss'] : ''}`}
      ref={scrollContainerRef}
    >
      {Notifications}
      {isPending && Skeletons}
    </div>
  );
}
